import { render, staticRenderFns } from "./FrameUrl.vue?vue&type=template&id=ba3746ae&scoped=true&"
import script from "./FrameUrl.vue?vue&type=script&lang=js&"
export * from "./FrameUrl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba3746ae",
  null
  
)

export default component.exports