<template>
  <div>
    <iframe v-if="isUrl" id="myIframe" :src="url" width="100%" height="100%" frameborder="0"></iframe>
    <div v-html="url"></div>
  </div>
</template>

<script>
export default {
  name: 'FrameUrl',
  data() {
    return {
      url: undefined,
      isUrl: false


    }
  },
  created() { },
  components: {},
  methods: {
    goFullScreen() {
      var timer = setInterval(() => {
        var iframe = document.getElementById('myIframe');
        iframe.style.width = '100vw'; // 视口宽度
        iframe.style.height = '100vh'; // 视口高度
        iframe.style.border = 'none'; // 移除边框
        clearInterval(timer);
      }, 200)

    }
  },
  mounted() {
    this.url = this.$route.query.url
    this.isUrl = !this.url.includes("DOCTYPE")

    this.goFullScreen()
  },
}

</script>
<style lang="scss" scoped></style>